import React from 'react';
import ReactDOM from 'react-dom/client';
// import { hot } from 'react-hot-loader';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';

import './components/i18n';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './components/HO/State';
import { AuthProvider } from './components/HO/Auth';

// library.add(fas);

const root = ReactDOM.createRoot(document.getElementById('root'));

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'admin') {
    return import('./components/HO/App/AdminAppClient');
  }

  if (process.env.REACT_APP_BUILD_TARGET === 'vendor') {
    return import('./components/HO/App/VendorAppClient');
  }

  return import('./components/HO/App/UserAppClient');
}

importBuildTarget().then(({ default: App }) => root.render(
  <StateProvider>
    <AuthProvider>
      {process.env.REACT_APP_ENV === 'dev' ? (
        <App />
      ) : (
        <React.StrictMode>
          <App />
        </React.StrictMode>
      )}
    </AuthProvider>
  </StateProvider>,
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
