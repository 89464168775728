const host = process.env.REACT_APP_API_BASE_URL;
const target = process.env.REACT_APP_BUILD_TARGET;

const routes = {
  auth: `${host}/${target || 'user'}/account`,
  translation: `${host}/api/translation`,
  languages: `${host}/api/language/search`,
  currencies: `${host}/api/currency/search`,
  configs: `${host}/api/config/search`,

  // forms

  invitation: `${host}/user/invitation`,
  social: `${host}/user/social`,
  socialAttach: `${host}/user/social/attach`,
  socialDetach: `${host}/user/social/detach`,
  login: `${host}/${target || 'user'}/login`,
  registration: `${host}/${target || 'user'}/registration`,

  accountUpdate: `${host}/${target || 'user'}/account/update`,
  deleteAvatar: `${host}/${target || 'user'}/account/avatar/delete`,
  deleteCompanyAvatar: `${host}/vendor/vendor/avatar/delete`,
  deleteWorkerAvatar: `${host}/vendor/worker/avatar/delete`,
  notificationsUpdate: `${host}/${target || 'user'}/account/notifications/update`,
  uploadAvatar: `${host}/${target || 'user'}/account/avatar`,
  uploadCompanyAvatar: `${host}/vendor/vendor/avatar`,
  uploadWorkerAvatar: `${host}/vendor/worker/avatar`,
  like: `${host}/${target || 'user'}/account/like`,
  notificationRead: `${host}/api/notification/read/all`,

  bankAccountEdit: `${host}/api/bank-account/edit`,

  earnsUpdateStatus: `${host}/vendor/earn/status/update`,
  earnsExport: `${host}/vendor/earn/export`,

  emailVerifyNotice: `${host}/api/email/verify/notice`,
  emailVerify: `${host}/api/email/verify`,

  orderAttach: `${host}/vendor/order/complete/attach`,
  orderAssignWorkers: `${host}/vendor/order/assign/workers`,
  orderCancel: `${host}/${target || 'user'}/order/cancel`,
  orderRepeat: `${host}/user/order/repeat`,
  orderCreate: `${host}/${target || 'user'}/order/create`,
  orderInventoryEdit: `${host}/user/order/inventory/edit`,
  orderComplete: `${host}/vendor/order/complete`,
  orderStart: `${host}/vendor/order/start`,
  orderEstimation: `${host}/vendor/order/estimation`,
  orderConvert: `${host}/vendor/order/convert`,
  orderWorked: `${host}/vendor/order/worked`,

  pageCreate: `${host}/admin/page/create`,
  pageEdit: `${host}/admin/page/edit`,
  sendPagesToProd: `${host}/admin/page/send`,

  emailTemplateEdit: `${host}/admin/template/email/edit`,
  sendEmailTemplatesToProd: `${host}/admin/template/email/send`,
  testEmailTemplate: `${host}/admin/template/email/test`,
  pushTemplateEdit: `${host}/admin/template/push/edit`,
  sendPushTemplatesToProd: `${host}/admin/template/push/send`,

  passwordConfirmNotice: `${host}/api/password/confirm/notice`,
  passwordConfirm: `${host}/${target || 'user'}/password/confirm`,

  reviewCreate: `${host}/user/review/create`,
  remarkCreate: `${host}/vendor/remark/create`,

  serviceCreate: `${host}/vendor/service/create`,
  serviceEdit: `${host}/vendor/service/edit`,
  servicesActivate: `${host}/vendor/service/activate`,
  servicesDeactivate: `${host}/vendor/service/deactivate`,
  servicesDelete: `${host}/vendor/service/delete`,

  houseCreate: `${host}/user/house/create`,
  houseEdit: `${host}/user/house/edit`,
  houseDelete: `${host}/user/house/delete`,
  houseDefault: `${host}/user/house/default`,

  friendInvite: `${host}/user/friend/invite`,
  inviteDelete: `${host}/user/friend/delete`,
  vendorInvite: `${host}/user/vendor/invite`,
  vendorRefer: `${host}/user/vendor/refer`,
  resendRefer: `${host}/user/vendor/refer/resend`,

  messageCreate: `${host}/api/message/create`,

  sendTransToProd: `${host}/admin/translation/send`,
  translationEdit: `${host}/admin/translation/edit`,

  inventoryCreate: `${host}/${target || 'user'}/inventory/create`,
  inventoryEdit: `${host}/user/inventory/edit`,
  inventoryDelete: `${host}/user/inventory/delete`,

  vendorAccess: `${host}/user/vendor/access`,
  vendorCreate: `${host}/vendor/vendor/create`,
  vendorEdit: `${host}/vendor/vendor/edit`,
  vendorStatusEdit: `${host}/admin/vendor/status/edit`,
  vendorClientBonusEdit: `${host}/vendor/bonus/client/edit`,
  vendorWorkerBonusEdit: `${host}/vendor/bonus/worker/edit`,

  workerCreate: `${host}/vendor/worker/create`,
  workerEdit: `${host}/vendor/worker/edit`,
  workerFire: `${host}/vendor/worker/fire`,
  workerHire: `${host}/vendor/worker/hire`,
  resendWorker: `${host}/vendor/worker/resend`,

  // data

  bankAccount: `${host}/api/bank-account`,

  brands: `${host}/api/brand/search`,
  category: `${host}/api/category`,
  categories: `${host}/api/category/search`,

  certificates: `${host}/${target || 'user'}/certificate/search`,

  city: `${host}/api/city`,
  cities: `${host}/api/city/search`,

  countries: `${host}/api/country/search`,

  financeCost: `${host}/user/finance/cost`,

  client: `${host}/vendor/client`,
  clients: `${host}/vendor/client/search`,
  clientsCount: `${host}/vendor/client/count`,
  clientInvite: `${host}/vendor/client/invite`,

  earns: `${host}/vendor/earn/search`,

  friend: `${host}/user/friend`,
  friends: `${host}/user/friend/search`,
  friendsCount: `${host}/user/friend/count`,
  friendParent: `${host}/user/friend/parent`,

  house: `${host}/user/house`,
  houses: `${host}/${target || 'user'}/house/search`,
  housesCategories: `${host}/user/house/category/search`,

  inventory: `${host}/${target || 'user'}/inventory/search`,
  inventoryAttachments: `${host}/user/inventory/attachment/search`,
  inventoryItem: `${host}/user/inventory`,
  inventoryTypes: `${host}/api/inventory/type/search`,

  messages: `${host}/api/message/search`,
  notifications: `${host}/api/notification/search`,
  emailReceiversEdit: `${host}/vendor/account/receivers/update`,

  oAuthProviders: `${host}/user/account/oauth/search`,

  order: `${host}/${target || 'user'}/order`,
  orderAttachments: `${host}/${target || 'user'}/order/attachment/search`,
  orders: `${host}/${target || 'user'}/order/search`,
  ordersCount: `${host}/${target || 'user'}/order/count`,

  emailTemplate: `${host}/admin/template/email`,
  emailTemplates: `${host}/admin/template/email/search`,
  pushTemplate: `${host}/admin/template/push`,
  pushTemplates: `${host}/admin/template/push/search`,

  page: `${host}/api/page`,
  pages: `${host}/admin/page/search`,

  relations: `${host}/admin/relation/search`,
  devices: `${host}/admin/device/search`,

  reviews: `${host}/${target || 'user'}/review/search`,

  service: `${host}/vendor/service`,
  services: `${host}/${target || 'user'}/service/search`,

  states: `${host}/api/state/search`,

  translations: `${host}/admin/translation/search`,
  emailsLog: `${host}/admin/email/search`,
  emailLogResend: `${host}/admin/email/resend`,

  vendor: `${host}/user/vendor`,
  vendors: `${host}/${target || 'user'}/vendor/search`,
  vendorsCount: `${host}/user/vendor/count`,

  invitationVendor: `${host}/invitation/vendor`,
  invitationServices: `${host}/invitation/service/search`,

  worker: `${host}/vendor/worker`,
  workers: `${host}/${target || 'user'}/worker/search`,
  workersCount: `${host}/vendor/worker/count`,
  workerQRCode: `${host}/${target || 'user'}/${(target || 'user') === 'user' ? 'vendor' : 'worker'}/qrcode`,
};

export default routes;
