import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getI18n } from 'react-i18next';

import { Provider } from './StateContext';

import routes from '../../../routes/routes';

const StateProvider = ({ children }) => {
  const { language } = getI18n();

  const [showLoading, setShowLoading] = useState(true);

  const controllers = [];
  const initialOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'App-Target': process.env.REACT_APP_BUILD_TARGET,
      Language: language,
    },
    mimeType: 'multipart/form-data',
  };

  const sendRequest = (route, options) => {
    const opts = { ...initialOptions, ...options || {} };

    if (opts.body) {
      if (opts.headers['Content-Type'] === 'application/json') {
        opts.body.target = process.env.REACT_APP_BUILD_TARGET || 'user';
        opts.body = JSON.stringify(opts.body);
      } else if (opts.headers.type === 'formData') {
        opts.body.append('target', process.env.REACT_APP_BUILD_TARGET || 'user');
      } else if (typeof opts.body === 'object') {
        opts.body.target = process.env.REACT_APP_BUILD_TARGET || 'user';
        opts.body = new URLSearchParams(opts.body);
      }
      if (opts.headers['Content-Type'] === 'multipart/form-data') {
        delete opts.headers['Content-Type'];
        delete opts.headers['content-type'];
      }
    }

    if (route in routes) {
      opts.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN)}`;
      // opts.headers.DeviceInfo = JSON.stringify({
      //   platform: 'android',
      //   manufacturer: 'dell',
      //   operatingSystem: 'debian',
      //   model: 'Inspiron 5770',
      // }); // TODO

      if (route in controllers) {
        controllers[route].abort();
      }

      controllers[route] = new AbortController();
      opts.signal = controllers[route].signal;

      let url = routes[route];
      if (['social', 'socialAttach'].indexOf(route) > -1) {
        url += window.location.search;
      }

      return fetch(url, opts).then((response) => {
        if (route in controllers) {
          delete controllers[route];
        }

        if (!response.ok) {
          return {
            errors: {
              route: `Route "${route}" no ok`,
            },
          };
        }

        if (response.url.slice(-8) === 'download') {
          return response.blob();
        }

        return response.text()
          .then((text) => {
            try {
              return JSON.parse(text);
            } catch (e) {
              console.log(text);
              return text;
            }
          });
      }).catch((error) => {
        if (route in controllers) {
          delete controllers[route];
        }

        return {
          errors: {
            route: error,
          },
        };
      });
    }

    console.error(`Route "${route}" does not exists in routes config`);

    return Promise.resolve({
      errors: {
        route: `Route "${route}" does not exists in routes config`,
      },
    });
  };

  const abortRequest = (route) => {
    if (route in controllers) {
      controllers[route].abort();
    }
  };

  const currentProps = {
    value: {
      showLoading,
      setShowLoading,
      sendRequest,
      abortRequest,
      routes,
    },
  };

  return (
    <Provider {...currentProps}>{children}</Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

StateProvider.defaultProps = {

};

export default StateProvider;
