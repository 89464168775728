import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import routes from '../routes/routes';

i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'en',
    fallbackLng: false,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `${routes.translation}/{{lng}}/{{ns}}`,
    },
  });

export default i18next;
// export { resources };
